import axios from "axios";

import { message } from "ant-design-vue";
import * as config from "@/utils/config.ts";
import Cookies from "js-cookie";

import router from "@/routes/protectedRoute";

class Request {
  constructor() {
    this.instance = axios.create({
      baseURL: config.API_URL,
      timeout: 30000,
    });
    this.instance.defaults.withCredentials = true;

    this.instance.interceptors.request.use(
      (config) => {
        config.headers["token"] = Cookies.get("token") || "";

        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    /*全局响应拦截器*/
    this.instance.interceptors.response.use(
      (response) => {
        const res = response.data;

        if (config.API_RES_CODE_ALERT.includes(res.code) && res.msg) {
            
          if (config.API_RES_CODE_SUC.includes(res.code)) {
            message.success(res.msg);
          } else if (config.API_RES_CODE_WARN.includes(res.code)) {
            message.warning(res.msg);
            
          } else {
            message.error(res.msg);
            if(res.code==777) {
                router.push("/login");
            }
          }
        }

        return res;
      },
      (err) => {
        if (err && err.response.status === 501) {
          router.push("/");
          Cookies.set("relogin", 1);
        }

        return false;

        /*return 这个会报错*/
        /*return Promise.reject(err);*/
      }
    );
  }

  /**
   * 发送get请求
   * @param url
   * @param data
   * @returns
   */
  get(url, data) {
    return this.instance.get(url, { params: data });
  }

  /**
   * 发送post请求
   * @param url
   * @param data
   * @param config
   *
   * @returns
   */
  post(url, data = undefined, config = undefined) {
    return this.instance.post(url, data, config);
  }

  put(url, data = undefined, config = undefined) {
    return this.instance.put(url, data, config);
  }

  delete(url, data) {
    return this.instance.delete(url, { params: data });
  }
}

export default new Request();
